import {
  filterByEligible,
  useDebouncedEffect,
} from '@coverright/utils';
import { Box, Container, Typography } from '@mui/material';
import { CRPreloader } from '@coverright/ui/shared';
import PlanTopFilter from './components/PlanTopFilter';
import PlanListSort from './components/PlanListSort';
import * as React from 'react';
import PlanCard from './components/PlanCard';
import { PlanFilterContext } from './PlanFilterContext';
import { useSnackbar } from 'notistack';
import * as _ from 'lodash';
import paths from '../config/router-paths';
import { environment } from '../../environments/environment';
import MGCategory from '../category/mgcategory';
import { MedigapQuoteContext } from '@coverright/shared/contexts';
import {
  useCreateMedicareQuoteFromMedigapQuote,
  useIsMAState,
  useIsWIState,
  useZipCountyFips,
} from '@coverright/data-access/medicare';
import {
  MedigapPlanName,
  MedigapPlansField,
  Sort,
} from '@coverright/data-access/types/medigap';
import {
  addDetailsToMGPlanList,
  MedigapPlanOutputWithDetails,
  useMedigapPlans,
} from '@coverright/data-access/medigap';
import { KeycloakContext } from '@coverright/shared/keycloak';
import MarketplaceSideContent from './components/MarketplaceSideContent';
import { useLogEvent } from '@coverright/shared/analytics';
import { useParams } from 'react-router';
import { MarketplaceDisclamers } from '@coverright/ui/marketplaces';
import { UpdateZip } from '@coverright/widgets';

interface PlansDictionary {
  [type: string]: MedigapPlanOutputWithDetails[];
}

function Marketplace() {
  const { zip, countyName } = useParams<{ zip: string; countyName: string }>();
  const pageSize = 1000;
  const [open, setOpen] = React.useState(false);
  const [sortBy, setSortBy] = React.useState<MedigapPlansField>(
    MedigapPlansField.MonthlyPremium
  );
  const [plans, setPlans] = React.useState<PlansDictionary>({});
  const [loading, setLoading] = React.useState(false);
  const { getHash } = React.useContext(KeycloakContext);
  const { values: filterValues, switchZip } =
    React.useContext(PlanFilterContext);
  const { id } = React.useContext(MedigapQuoteContext);
  const { enqueueSnackbar } = useSnackbar();
  const [total, setTotal] = React.useState<number>();
  const [page, setPage] = React.useState({
    page: 0,
    size: pageSize,
  });
  const [createMAQuote] = useCreateMedicareQuoteFromMedigapQuote({
    onCompleted: (data) =>
      (document.location.href = `${environment.medicareAdvantageUrl}?quote=${data.createMedicareQuoteFromMedigapQuote}`),
  });

  const isMassachusetts = useIsMAState(zip);
  const isWisconsin = useIsWIState(zip);

  const [request, results] = useMedigapPlans();
  const { data } = useZipCountyFips({ variables: { zip: zip! } });
  const logEvent = useLogEvent();

  React.useEffect(() => {
    logEvent('Page view - Marketplace', { Marketplace: 'MG' });
  }, []);

  useDebouncedEffect(
    () => {
      if (filterValues?.zip && filterValues?.effectiveDate) {
        //      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setPlans({});
        setLoading(true);
        setPage({
          page: 0,
          size: pageSize,
        });
      }
    },
    700,
    [filterValues, sortBy, isMassachusetts, isWisconsin]
  );

  React.useEffect(() => {
    if (results.data) {
      setTotal(results.data.medigapPlans.totalElements);
      setPlans(() => {
        if (results.data) {
          const result = _.groupBy(
            addDetailsToMGPlanList(
              results.data.medigapPlans.data.filter((plan) =>
                filterByEligible(
                  filterValues?.age,
                  filterValues?.effectiveDate
                )(plan.planName as MedigapPlanName)
              )
            ),
            'planName'
          );
          setLoading(false);
          return result;
        }
        return {} as PlansDictionary;
      });
    }
  }, [results.data, filterValues]);

  const requestCallback = React.useCallback(() => {
    const filters: any = { ...filterValues };
    delete filters.selectedPlanCategory;
    filters.zip = filters.zip || zip;
    filters.county = filters.county || countyName;
    if (isMassachusetts || isWisconsin) {
      filters.planNames = [];
    }

    if (filters.zip && page && filters.age && filters.effectiveDate) {
      request({
        variables: {
          filter: filters,
          page,
          sort: [
            {
              direction: Sort.Asc,
              field: sortBy,
            },
          ],
        },
      });
    }
  }, [zip, countyName, filterValues, isMassachusetts, isWisconsin]);

  React.useEffect(() => {
    requestCallback();
  }, [page]);

  React.useEffect(() => {
    if (results.error) {
      enqueueSnackbar('Something wrong. Please try again later.', {
        variant: 'error',
      }); // todo add text
    }
  }, [results.error]);

  const onUpdateZipModalClose = React.useCallback(
    (newZip?: string, newCounty?: string) => {
      if (newZip && newCounty && (newCounty !== countyName || newZip !== zip)) {
        switchZip(newZip, newCounty).then(() => {
          document.location.href = paths.plans + newZip + '/' + newCounty;
        });
      }
    },
    [zip, countyName, filterValues]
  );

  return (
    <Container maxWidth={'lg'} sx={{ mb: 30 }}>
      <Typography
        sx={{
          fontSize: { xs: 20, sm: 32 },
          lineHeight: { xs: '25px', sm: '40px' },
        }}
      >
        Here are your Medicare Supplement options.
      </Typography>
      <UpdateZip
        zip={zip}
        county={countyName}
        onChange={onUpdateZipModalClose}
      />
      <Box sx={{ display: 'flex', gap: 4 }}>
        <MarketplaceSideContent goToCategory={() => setOpen(true)} />

        <div>
          <MGCategory open={open} onClose={() => setOpen(false)} />

          <PlanTopFilter zip={zip!} goToCategory={() => setOpen(true)} />
          <Box mt={'16px'}>
            <PlanListSort
              onChange={setSortBy}
              value={sortBy}
              zip={filterValues?.zip}
              county={(filterValues?.county as string) || undefined}
            />

            {!!filterValues && (
              <>
                {!!Object.keys(plans).length &&
                  Object.keys(plans).map((key) => (
                    <PlanCard
                      plans={plans[key]}
                      zip={filterValues?.zip}
                      countyName={filterValues?.county as string}
                      key={key}
                    />
                  ))}
                {!Object.keys(plans).length && !loading && results.called && (
                  <Typography
                    color={'textPrimary'}
                    align={'center'}
                    className={'fs-18 bold mt-50 mb-110'}
                  >
                    There are no plans available based on your search criteria.
                    Try adjusting your filter settings to see more plans.
                  </Typography>
                )}
              </>
            )}

            {loading && (
              <Box textAlign={'center'} my={2} width={'100%'}>
                <CRPreloader sx={{ margin: 'auto', width: '100px' }} />
              </Box>
            )}

            <hr className={' mt-40'} />

            <MarketplaceDisclamers hideMultiplan />
          </Box>
        </div>
      </Box>
    </Container>
  );
}

export default Marketplace;

const paths = {
  home: '/',
  plans: '/plans/',
  drugs: '/drugs/',
  pharmacies: '/pharmacies/',
  category: '/category/',
  completed: '/completed/',
  personal: '/personal/',
  received: '/received',
};

export default paths;

import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import { MedigapFilterStateOutput, QueryMedigapFilterStateArgs } from '@coverright/data-access/types/medigap';

export function useMedigapFilterState() {
  return useLazyQuery<{medigapFilterState: MedigapFilterStateOutput}, QueryMedigapFilterStateArgs>(gql(filtersQuery))
}

const filtersQuery = `
  query($id: UUID!) {
    medigapFilterState(id: $id) {
      age
      benefits
      companies
      county
      effectiveDate
      gender
      id
      monthlyPlanPremiumRanges
      planCategory
      planNames
      tobacco
      zip
    }
  }
`;

import { MutationHookOptions, useMutation } from '@apollo/client';
import { MutationCreateMedigapQuoteForClientArgs, SaveMedigapQuoteOutput } from '@coverright/data-access/types/medigap';
import { gql } from '@apollo/client';

export function useCreateMedigapQuoteForClient(options?: MutationHookOptions<{createMedigapQuoteForClient: SaveMedigapQuoteOutput}, MutationCreateMedigapQuoteForClientArgs>) {
  return useMutation<{createMedigapQuoteForClient: SaveMedigapQuoteOutput}, MutationCreateMedigapQuoteForClientArgs>(gql(mutation), {
    ...options,
  })
}

const mutation = `
mutation ($agentOffer: Boolean, $input: MedigapQuoteInput!) {
  createMedigapQuoteForClient(input: $input, agentOffer: $agentOffer) {
    clientId
    mgQuoteId
  }
}
`;

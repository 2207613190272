import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationCreateMedicareQuoteFromMedigapQuoteArgs } from '@coverright/data-access/types/medicare';


export function useCreateMedicareQuoteFromMedigapQuote(options?: MutationHookOptions<{createMedicareQuoteFromMedigapQuote: string}, MutationCreateMedicareQuoteFromMedigapQuoteArgs>) {
  return useMutation<{createMedicareQuoteFromMedigapQuote: string}, MutationCreateMedicareQuoteFromMedigapQuoteArgs>(
    gql(request),
    {
      ...options,
    }
  );
}


const request = `
mutation ($medigapQuoteId: UUID!) {
  createMedicareQuoteFromMedigapQuote(medigapQuoteId: $medigapQuoteId)
}
`;

import { isPersonEligible } from '@coverright/utils';
import { MedigapPlanName } from '@coverright/data-access/types/medigap';
import moment from 'moment';

export const isEffectiveDateValid = (date?: string): boolean => {
  if (!date) {
    return false
  }

  return moment(date).isValid() && moment(date).isSameOrAfter(moment().startOf('day'))
}

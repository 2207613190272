import React from "react";
import {QueryLazyOptions} from "@apollo/client";
import { MedigapFilterItem, MedigapPlansFilterInput } from '@coverright/data-access/types/medigap';
import { useMGPlanFilterData } from '@coverright/data-access/medigap';

interface PlanFilterDataContextState {
  getPlanFilterData: (data?: QueryLazyOptions<{filter: MedigapPlansFilterInput}>) => void,
  planFilterData: PlanFilterData | undefined,
}

export const PlanFilterDataContext = React.createContext<PlanFilterDataContextState>({
  getPlanFilterData: () => {},
  planFilterData: undefined
});

export function PlanFilterDataProvider(props: React.PropsWithChildren<any>) {

  const [planFilterData, setPlanFilterData] = React.useState<PlanFilterData>();

  const [request, data] = useMGPlanFilterData();

  React.useEffect(() => {
    if (data.data) {
      setPlanFilterData({
        companies: transformListItem(data.data.medigapCompanies),
        planNames: transformListItem(data.data.medigapPlanNames),
        monthlyPlanPremium: transformListItem(data.data.medigapMonthlyPlanPremium),
        benefits: transformListItem(data.data.medigapBenefits),
      })
    }
  }, [data]);

  return <PlanFilterDataContext.Provider value={{getPlanFilterData: request, planFilterData}}>
    {props.children}
  </PlanFilterDataContext.Provider>
}

export const withPlanFilterDataContext = (WrappedComponent: any) => (props: any) => {
  return (
    <PlanFilterDataProvider>
      <WrappedComponent {...{...props}} />
    </PlanFilterDataProvider>
  )
}

export type ListItem = {
  value: any,
  label: any,
  disabled?: boolean,
  tooltip?: string
}

interface PlanFilterData {
  companies: ListItem[],
  planNames: ListItem[],
  monthlyPlanPremium: ListItem[],
  benefits: ListItem[],
}

type QuoteProviderProps = {
  quoteId?: string,
  private?: boolean,
}




function transformListItem(collection: MedigapFilterItem[]): ListItem[] {
  return collection.map((item: any) => ({
      value: item.key,
      label: item.name,
      disabled: item.count === 0
    })
  )
}

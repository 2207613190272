import React from "react";
import { Box, Card, Typography } from '@mui/material';
import { getLabelForCategory, planCategories, PlanCategory, PlanCategoryDescription } from './mgcategory';
import { MedigapQuoteContext } from '@coverright/shared/contexts';

interface CategorySelectorProps {
  selectedCategory: PlanCategory,
  onClick: (category: PlanCategory) => void
}

const CategorySelector = (props: CategorySelectorProps) => {

  return <Box sx={{
    minHeight: 80,
    mt: '30px',
    display: 'flex',
    gap: {xs: 1, md: 2},
    overflowY: 'auto',
    py: 1,
  }}>
    {planCategories.map((cat, i) => <div data-test={'mg_compare_modal_category_card_'+i} className={'flex'}>
      <CategoryCard key={i} ribbon={i === 0} active={props.selectedCategory === cat.name} onClick={() => props.onClick(cat.name)} category={cat} />
    </div>)}
  </Box>;
}

export default CategorySelector;


type CategoryCardProps = {
  category: PlanCategoryDescription,
  onClick: () => void,
  active: boolean,
  ribbon: boolean,
}

const CategoryCard = (props: CategoryCardProps) => {
  const {medigapFilterState} = React.useContext(MedigapQuoteContext);

  return <Card sx={{
    width: '100%',
    minWidth: 205,
    height: 57,
    minHeight: 57,
    boxShadow: 'none',
    background: props.active ? '#EFFAF5' : 'white',
    border: props.active ? '1px solid #1D9C80' : '1px solid #CCD8DC',
    p: '10px 15px',
    position: 'relative',
    overflow: 'visible',
    cursor: 'pointer'
  }} onClick={props.onClick}>
    {props.ribbon && <Box sx={{position: 'absolute', right: 7, top: -6}}>
      <img src={'/assets/img/ribbon.svg'} className={'w-25 h-32'} />
    </Box>}
    <Typography className={'fs-18 lh-22 bold mb-10'}>{props.category.name}</Typography>
    {getLabelForCategory({
      cat: props.category.name,
      sx: {display: 'none'},
      skipTitle: false,
      titleClassName: 'dark-green fs-14 bold',
      age: medigapFilterState?.age,
    })}
  </Card>
}

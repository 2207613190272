import React from "react";
import { useNavigate } from "react-router";
import {
  Box,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { colors } from '@coverright/ui/themes';
import { StyledSelect } from './StyledSelect';
import { MedigapPlansField } from '@coverright/data-access/types/medigap';
import { FavoritesContext } from '@coverright/shared/contexts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sort: {
      padding: '4px 14px 2px',
      fontSize: 13,
      color: colors.text.primary,
      cursor: 'pointer',
      borderRadius: 4,
      fontWeight: 500
    },
    sortActive: {
      color: colors.custom.green.variant5,
      fontWeight: 700
    },
  }),
);

interface PlanListSortProps {
  onChange: (value: MedigapPlansField) => void,
  value: MedigapPlansField,
  zip?: string
  county?: string
}

const PlanListSort = (props: PlanListSortProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {favorites} = React.useContext(FavoritesContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = (value: MedigapPlansField) => {
    props.onChange(value);
    handleClose();
  }

  const open = Boolean(anchorEl);

  return <>
    {!phone && <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'-16px'}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography className={'fs-16'}>Sort by:</Typography>
        <Box ml={'8px'}>
          <StyledSelect hideTick sx={{minWidth: 220, background: 'white!important'}} className={'mb-0'} value={props.value} onChange={e => props.onChange(e.target.value as MedigapPlansField)} >
            <MenuItem value={MedigapPlansField.MonthlyPremium}>Monthly premium</MenuItem>
            <MenuItem value={MedigapPlansField.PlanName}>Plan type</MenuItem>
          </StyledSelect>
        </Box>
      </Box>
      {/*<div className={'flex flex-align-center pointer'} onClick={() => navigate(paths.favorites + props.zip + '/' + props.county)}>
        <Typography className={'fs-12 medium'} color={'textPrimary'}>See your favorites</Typography>
        <div className={'w-5'} />
        <Badge color="primary" overlap="circle" badgeContent={favorites.length} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
          <img width={30} height={30} src={'/img/heart.svg'}/>
        </Badge>
      </div>*/}
    </Box>}
    {phone && <Box display={'flex'} alignItems={'center'} mb={'-16px'}>
      <Typography className={'fs-16'}>Sort by:</Typography>
      <Box ml={'8px'}>
        <StyledSelect sx={{minWidth: 220, background: 'white!important'}} className={'mb-0'} value={props.value} onChange={e => props.onChange(e.target.value as MedigapPlansField)} >
          <MenuItem value={MedigapPlansField.MonthlyPremium}>Monthly premium</MenuItem>
          <MenuItem value={MedigapPlansField.PlanName}>Plan type</MenuItem>
        </StyledSelect>
      </Box>
    </Box>}
  </>;
}

export default PlanListSort;

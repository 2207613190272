import { ThemeProvider } from '@mui/material';
import { AppTheme } from '@coverright/ui/themes';
import { CRPreloader } from '@coverright/ui/shared';
import { LayoutWithNotification } from '@coverright/ui/marketplaces';
import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Navigate,
  Route,
  Routes, useLocation
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  getAgeByBirthdate,
  getClientId,
  getMedigapQuoteId,
  getStoredUser,
  resetStoredUser,
  setStoredUser
} from '@coverright/utils';
import paths from './config/router-paths';
import Marketplace from './marketplace/marketplace';
import { PlanFilterDataProvider } from './marketplace/PlanFilterDataContext';
import * as QueryString from 'query-string';
import {
  getDefaultVal,
  PlanFilterContextProvider,
} from './marketplace/PlanFilterContext';
import moment from 'moment';
import { withKeycloakContext, KeycloakContext } from '@coverright/shared/keycloak';
import { client, setErrorHandler } from '@coverright/data-access/apollo-clients';
import {
  AdminContextProvider, CompareOfferContextProvider, MedigapQuoteContext,
  MedigapQuoteProvider, withAppContextProvider
} from '@coverright/shared/contexts';
import { Gender } from '@coverright/data-access/types/medigap';
import AdminNotification from './components/AdminNotification';
import { identifyHotjar, useLogEvent } from '@coverright/shared/analytics';
import * as _ from 'lodash';
import { useParams } from 'react-router';

export function App() {
  const { enqueueSnackbar } = useSnackbar();
  const {keycloak, initialized, isAdmin} = React.useContext(KeycloakContext);
  const urlParams = new URLSearchParams(window.location.search);
  const logEvent = useLogEvent();
  const {pathname} = useLocation();

  React.useEffect(() => {
    if (initialized && !keycloak?.authenticated) {
      document.location.href = keycloak.createLoginUrl({redirectUri: document.location.href})
    }
  }, [initialized, keycloak?.authenticated]);

  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path, extensions }: any) => {
        switch (extensions?.status) {
          case '403':
            {
              resetStoredUser();
              document.location.reload();
            }
            break;
          default: {
            console.error(
              `Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}`
            );
            enqueueSnackbar('Network error, please try again later', {
              variant: 'error',
            });
          }
        }
      });
  });

  React.useEffect(() => {
    identifyHotjar()
    if (pathname) {
      const pageName = _.startCase(_.last(pathname.split('/')));
      logEvent('page_view', {page_title: pageName})
    }
  }, [pathname])

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('advisor')) {
      sessionStorage.setItem('advisor', 'true');
    }
    if (urlParams.has('y2y')) {
      sessionStorage.setItem('y2y', 'true');
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={AppTheme}>
        <AdminContextProvider clientId={getClientId()}>
          <MedigapQuoteProvider quoteId={urlParams.has('adminQuote') ? urlParams.get('adminQuote') as string : getMedigapQuoteId()}>
            <CompareOfferContextProvider adminOnly>
              <PlanFilterDataProvider>
                <LayoutWithNotification showNotification NotificationComponent={<AdminNotification />}>
                    <Helmet>
                      <title>CoverRight</title>
                      <meta
                        name="description"
                        content="Find the best Medicare plan for you"
                      />
                    </Helmet>
                    <PlanFilterContext />
                </LayoutWithNotification>
              </PlanFilterDataProvider>
            </CompareOfferContextProvider>
          </MedigapQuoteProvider>
        </AdminContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default _.flowRight([withKeycloakContext, withAppContextProvider])(App);

const PlanFilterContext = () => {
  const params = QueryString.parse(document.location.search);
  const { zip, countyName } = useParams<{ zip: string; countyName: string }>();
  const urlParams = new URLSearchParams(window.location.search);
  const quote = React.useContext(MedigapQuoteContext);

  const init = (callback: any) => {
    const birthDate =
      urlParams.get('birthdate') && moment(urlParams.get('birthdate')).isValid()
        ? moment(urlParams.get('birthdate')).format('YYYY-MM-DD')
        : getStoredUser()?.birthDate;

    let age = birthDate
      ? getAgeByBirthdate(birthDate)
      : getStoredUser()?.age
    if (!age || age >= 63 && age < 65) {
      age = 65;
    }

    setStoredUser({
      zip: urlParams.get('zip') || getStoredUser()?.zip,
      firstName: urlParams.get('name') || getStoredUser()?.firstName,
      lastName: urlParams.get('last_name') || getStoredUser()?.lastName,
      birthDate,
      age: age.toString(),
      phoneNumber: urlParams.get('phone') || getStoredUser()?.phoneNumber,
      email: urlParams.get('email') || getStoredUser()?.email,
      medigapQuoteId:
        urlParams.get('medigapQuote') || getStoredUser()?.medigapQuoteId,
      gender: urlParams.has('gender')
        ? urlParams.get('gender') === 'female'
          ? Gender.F
          : Gender.M
        : getStoredUser()?.gender,
    });
    return callback();
  }

  if (!quote.id) {
    return <CRPreloader sx={{ height: '100vh', display: 'flex', alignItems: 'center', my: 0 }}/>
  }

  return (
    <PlanFilterContextProvider
      initFilter={
        sessionStorage.getItem('medigapFilters')
          ? JSON.parse(sessionStorage.getItem('medigapFilters') || '{}')
          : init(() => getDefaultVal(!!params.hd, zip!, countyName!))
      }
    >
      <Routes>
        <Route
          path={paths.plans + ':zip/:countyName'}
          element={<Marketplace />}
        />
        <Route path={paths.home} element={<Navigate to={`${paths.plans}${quote.medigapFilterState?.zip}/${quote.medigapFilterState?.county}`} />} />
      </Routes>
    </PlanFilterContextProvider>
  );
};

import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { MedigapFilterItem, MedigapPlansFilterInput } from '@coverright/data-access/types/medigap';

export function useMGPlanFilterData(options?: LazyQueryHookOptions<IMGPlanFilterResponse, {filter: MedigapPlansFilterInput}>) {
  return useLazyQuery<IMGPlanFilterResponse, {filter: MedigapPlansFilterInput}>(gql(planFilterQuery), options)
}

export function useMGPlanFilterDataShort(options?: LazyQueryHookOptions<IMGPlanFilterResponseShort, {filter: MedigapPlansFilterInput}>) {
  return useLazyQuery<IMGPlanFilterResponseShort, {filter: MedigapPlansFilterInput}>(gql(planFilterQueryShort), options)
}

export interface IMGPlanFilterResponse {
  medigapCompanies: MedigapFilterItem[],
  medigapPlanNames: MedigapFilterItem[],
  medigapMonthlyPlanPremium: MedigapFilterItem[],
  medigapBenefits: MedigapFilterItem[],
}

export interface IMGPlanFilterResponseShort {
  medigapCompanies: MedigapFilterItem[],
}

const planFilterQuery = `
  query($filter: MedigapPlansFilterInput!) {
    medigapBenefits {
      key
      name
    }
    medigapMonthlyPlanPremium {
      key
      name
    }
    medigapPlanNames(filter: $filter) {
      key
      name
      count
    }
    medigapCompanies(filter: $filter) {
      key
      name
      count
    }
  }
`;

const planFilterQueryShort = `
  query($filter: MedigapPlansFilterInput!) {
    medigapCompanies(filter: $filter) {
      key
      name
      count
    }
  }
`;

import { styled } from '@mui/material';
import { SelectInput } from '@coverright/ui/inputs';

export const StyledSelect = styled(SelectInput)({
  marginBottom: '0!important',
  '& div.MuiOutlinedInput-root': {
    background: 'white',
    paddingLeft: 0,
  },
  '& div.MuiOutlinedInput-input': {
    fontSize: '14px!important',
    fontWeight: '400!important',
    padding: '9px 9px 5px!important',
    zIndex: 1,
    lineHeight: '17px!important',
    background: 'transparent',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    height: '37px!important',
  },
  '& .MuiSelect-icon': {
    top: 'calc(50% - 13px)',
    background: 'white',
    zIndex: 2,
    paddingLeft: '3px',
    bottom: 3
  }
})

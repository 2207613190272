import React from "react";
import { Box } from '@mui/material';
import PlanFilter from "./PlanFilter";

const MarketplaceSideContent = (props: {goToCategory: () => void}) => (
  <Box sx={{minWidth: 304, maxWidth: 304, display: 'flex', flexDirection: 'column'}}>
    <PlanFilter {...props} goToCategory={() => props.goToCategory()} />
  </Box>
)

export default MarketplaceSideContent;
